import * as React from "react"
import Container from "components/container"
import styled from "@emotion/styled"
import { getColorScheme, getWhiteText, theme as themeCommon } from "common/colorScheme"
import { css } from "@emotion/react"
import BackgroundAnimation from "components/BackgroundAnimation"
import { mediaBreakpoint, BreakPoints } from "../../settings/breakpoints"
import { H2, P } from "../headings"
import Button from "../button"
import Media from "../Media"
import { SectionMediaAlign, MediaSize, SectionLayoutAlign, ISectionProps } from "./section.model"
import { elementData, formatMultilineText } from "./utils"
import { getSizeStyles, useTitleSize } from "./TitleSizeContext"
import { useSectionTheme } from "./SectionThemeContext"
import {AdditionalText} from "./ImageRight"

interface IBannerSectionProps extends ISectionProps {}

const backgroundGradientWrapper = css`
	height: 100%;
	width: 100%;
	position: relative;
`

const hideOverflow = css`
	top: 0;
	position: absolute;
	height: 100%;
	width: 100%;
	overflow: hidden;
	pointer-events: none;
`

export const hideOnMobile = css`
	display: none;

	${mediaBreakpoint(BreakPoints.LG)} {
		display: block;
	}
`

const containerStyles = css`
	display: block;
	padding-top: ${themeCommon.spacing(6)};
	padding-bottom: ${themeCommon.spacing(6)};
	${mediaBreakpoint(BreakPoints.MD)} {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding-top: 0;
		padding-bottom: 0;
	}
`

const SubTitle = styled.h3`
	font-size: 2rem;
	line-height: 2.8rem;
	font-weight: 400;
`

const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	flex-direction: column;

	div:not(:nth-of-type(1)) {
		margin-top: ${themeCommon.spacing(2)};
	}

	&.hideOnMobile {
		display: none;

		${mediaBreakpoint(BreakPoints.SM)} {
			display: flex;
		}
	}

	&.hideOnDesktop {
		display: flex;
		margin-top: ${themeCommon.spacing(6)};

		${mediaBreakpoint(BreakPoints.SM)} {
			display: none;
		}
	}

	${mediaBreakpoint(BreakPoints.SM)} {
		width: fit-content;
		flex-direction: row;

		div:not(:nth-of-type(1)) {
			margin-top: 0px;
		}
	}
`

const buttonStyles = css`
	width: 100%;

	&:not(:first-of-type) {
		margin-top: ${themeCommon.spacing(1.5)};

		${mediaBreakpoint(BreakPoints.SM)} {
			margin-left: ${themeCommon.spacing(3)};
			margin-top: 0;
		}
	}
`

const Cover = styled.div`
	display: flex;
	content-visibility: initial;
	flex-direction: column;
	${mediaBreakpoint(BreakPoints.SM)} {
		padding: ${themeCommon.spacing(7.5, 0)};
		align-items: center;
	}
`

const TextContainer = styled.div`
	flex-basis: 70%;
	margin: 4rem 2rem ;
	${H2} {
		${mediaBreakpoint(BreakPoints.LG)} {
			white-space: pre;
		}
	}
`

const MediaContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	flex-basis: 100%;
	max-width: 100%;
	${mediaBreakpoint(BreakPoints.MD)} {
		align-items: unset;
		flex-basis: 30%;
	}
	${mediaBreakpoint(BreakPoints.LG)} {
		flex-basis: 30%;
		max-width: 30%;
		margin: 4rem 6rem!important;
	}
`

const RadiusCorners = css `
	border-radius: 0px;
	max-width:1200px;
	height: 100%;
	width:100%;
	${mediaBreakpoint(BreakPoints.SM)} {
		height: 100%;
		border-radius: 10px;
	}
`

const alignContentFirsttStyles = css`
	${MediaContainer} {
		margin-top: ${themeCommon.spacing(6.5)};

		${mediaBreakpoint(BreakPoints.LG)} {
			margin-top: ${themeCommon.spacing(4)};
		}
	}

	${mediaBreakpoint(BreakPoints.LG)} {
		${TextContainer} {
			order: 1;
		}
		${MediaContainer} {
			order: 2;
			margin-top: 0;
		}
	}
`

const alignMediaFirstStyles = css`
	${MediaContainer} {
		margin-top: ${themeCommon.spacing(4)};
		margin-bottom: ${themeCommon.spacing(2)};
		order: 1;
	}

	${TextContainer} {
		order: 2;
	}

	${mediaBreakpoint(BreakPoints.LG)} {
		${MediaContainer} {
			margin-bottom: 0;
			margin-top: 0;
		}
	}
`


const layoutAlignStyles = {
	[SectionLayoutAlign.ContentFirst]: alignContentFirsttStyles,
	[SectionLayoutAlign.MediaFirst]: alignMediaFirstStyles,
}

const getMediaSize = (size: MediaSize) => {
	switch (size) {
	case MediaSize.VerySmall:
		return css`
				width: 300px;
			`
	case MediaSize.Small:
		return css`
				width: 350px;
			`
	case MediaSize.MediumSmall:
		return css`
				width: 400px;
			`
	case MediaSize.Medium:
		return css`
				width: 500px;
			`
	case MediaSize.Full:
		return css`
				${mediaBreakpoint(BreakPoints.LG)} {
					margin: ${themeCommon.spacing(-7.5, 0)};
				}
			`
	default:
		return css``
	}
}

const getMediaAlign = (alignment: SectionMediaAlign) => {
	switch (alignment) {
	case SectionMediaAlign.Left:
		return css`
				align-items: flex-start !important;
			`
	case SectionMediaAlign.Center:
		return css`
				align-items: center !important;
			`
	case SectionMediaAlign.Right:
	default:
		return css`
				align-items: flex-end !important;
			`
	}
}


const MediaWrapper = styled.div`
	max-width: 100%;
	width: auto;
`

const BannerSection: React.FC<IBannerSectionProps> = ({
	colorScheme,
	title,
	titleLevel,
	description,
	buttons,
	media,
	mediaAlign,
	mediaSize,
	layoutAlignment,
	customStyle,
	backgroundGradientsBottomLeft,
	backgroundGradientsBottomRight,
	backgroundGradientsTopLeft,
	backgroundGradientsTopRight,
	backgroundGradientsWidth,
	subTitle,
	additionalText,
}) => {
	const titleSize = useTitleSize()
	const theme = useSectionTheme()
	const whiteText = theme === "white-text"
	const gradientsWidth = backgroundGradientsWidth ?? 50
	const backgroundGradients =
		backgroundGradientsBottomLeft ||
		backgroundGradientsBottomRight ||
		backgroundGradientsTopLeft ||
		backgroundGradientsTopRight

	return (
		<section css={customStyle}>
			<div css={backgroundGradientWrapper} data-element={elementData.gradientWrapper}>
				<div css={[hideOverflow, hideOnMobile]}>
					<Container
						customStyles={css`
							position: relative;
							height: calc(100% - 202px);
							margin-top: 202px;
							transform: translateX(${100 - gradientsWidth}%);
						`}
					>
						{backgroundGradients && (
							<BackgroundAnimation
								width={gradientsWidth}
								backgroundGradientsBottomLeft={backgroundGradientsBottomLeft}
								backgroundGradientsBottomRight={backgroundGradientsBottomRight}
								backgroundGradientsTopLeft={backgroundGradientsTopLeft}
								backgroundGradientsTopRight={backgroundGradientsTopRight}
							/>
						)}
					</Container>
				</div>
				<Cover data-element={elementData.cover}>	
					<Container css={[containerStyles, layoutAlignStyles[layoutAlignment], getColorScheme(colorScheme), RadiusCorners]} data-element={elementData.container}>
						<TextContainer data-element={elementData.textContainer} css={getWhiteText(whiteText)}>
							{subTitle && <SubTitle>{subTitle}</SubTitle>}
							<H2 css={getSizeStyles(titleSize)} as={titleLevel || "h2"} data-element="title-text">
								{title}
							</H2>
							{description && <P>{formatMultilineText(description)}</P>}
							<ButtonsContainer className="hideOnMobile">
								{(buttons || []).map(button => {
									const { id } = button

									return (
										<div key={id} css={buttonStyles}>
											<Button {...button} />
										</div>
									)
								})}
							</ButtonsContainer>
						</TextContainer>
						<MediaContainer css={getMediaAlign(mediaAlign)} data-element={elementData.bannerMediaContainer}>
							{media ? (
								<MediaWrapper css={getMediaSize(mediaSize)} data-element={elementData.bannerMedia}>
									<Media
										imgStyle={{
											objectFit: "contain",
										}}
										{...media}
									/>
								</MediaWrapper>
							) : null}
						</MediaContainer>
						<ButtonsContainer className="hideOnDesktop">
							{(buttons || []).map(button => {
								const { id } = button

								return (
									<div key={id} css={buttonStyles}>
										<Button {...button} />
									</div>
								)
							})}
						</ButtonsContainer>
					</Container>
					{additionalText && (
						<Container>
							<AdditionalText
								sectionTheme={theme}
								dangerouslySetInnerHTML={{ __html: additionalText?.childMarkdownRemark.html }}
							/>
						</Container>
					)}
				
				</Cover>
			</div>
		</section>
	)
}

export default React.memo(BannerSection)
